import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../images/new-home-screenshot.png';
import SvgCharts from '../svg/SvgCharts';
import { Helmet } from "react-helmet"

export default () => (
<>
<Helmet title="VUE - FAQ" defer={false} />
<Layout>
<section className="pt-5 md:pt-5">
     <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-4/5">
          <h1 className="text-4xl lg:text-2xl xl:text-3xl font-bold leading-none">
 			Frequently Asked Questions
 		  </h1>
      <h3 className="mt-10" >Is VUE Open Source?</h3>
 		  <p className="text-md md:text-md mt-6 ml-10 font-light">Yes, VUE is released under the ECL (Education Commons License) version 2.0</p>		  
      <h3 className="mt-10" >Will VUE run on my mobile device?</h3>
      <p className="text-md md:text-md mt-6 ml-10 font-light">No, VUE is written in Java and we do not have funding to port VUE to mobile platforms.  It will nto run on any iOS or Android device.</p>      
      <h3 className="mt-10" >How can I contribute a translation of VUE to another language?</h3>
      <p className="text-md md:text-md mt-6 ml-10 font-light">
      Over the years, we have had many requests to translate VUE in different languages. Thanks to dedicated volunteers, this is now a reality. Translation is a work in progress. New languages can be added, as volunteers take on the task. Updating existing translations is also necessary to keep up with the evolution of VUE.  Contact us if you would like to participate in the translation effort.
      </p>      
      <h3 className="mt-10" >I can't email a VUE map</h3>
      <p className="text-md md:text-md mt-6 ml-10 font-light">
      Email clients will often render VUE maps as inline text in your email message instead of as an attachment. To avoid that, you can either save your map as a "vpk" (in VUE) or compress it as a "zip" or "sit" file with a third party software.
      </p>
      <h3 className="mt-10" >Where the videos from the old VUE website?</h3>
      <p className="text-md md:text-md mt-6 ml-10 font-light">
      The videos are being moved to Youtube, they're now linked under social media on the bottom of the site.  You may notice we have two Youtube channels, we
       can no longer access the original site, the one linked from this website is our current Youtube channel.
      </p>
      <h3 className="mt-10" >Is Mac OS Catalina currently supported?</h3>
      <p className="text-md md:text-md mt-6 ml-10 font-light">
      As of December 18, 2019 we do not currently support Mac OS 10.15 because of changes Apple made in their java support.  We are working on a new release that will address this.
      </p>
 		</div>
    </div>
</section>
</Layout>
</>
)